import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tagsView: {},
    addTitles: "",
    currenPagePath: "",
    fileNameObj: {}
  },
  mutations: {
    SET_TAGSVIEWS: (state, tags) => {
      state.tagsView = tags ? tags : {};
    },
    SET_CURRENTPAGEPATH: (state, path) => {
      state.currenPagePath = path ? path : "";
    },
    SET_ADDTITLES: (state, addTitles) => {
      console.log("addTitles", addTitles);
      state.addTitles = addTitles ? addTitles : "";
    },
    SET_FILENAME: (state, fileNameObj) => {
      state.fileNameObj = fileNameObj ? fileNameObj : {};
    }
  },
  actions: {},
  modules: {}
});
