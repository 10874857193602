export function validUsername(str) {
  return /^1[3456789]\d{9}$/.test(str);
}

export function delMarkTag(template, flag) {
  console.log("==============================");
  console.log(template, flag);
  let vueDataAttr = `([\\w\\-]+\\s*(=)\\s*(""|'')\\s+)*`;
  let p = `[\\w\\s\\-]*`;
  // let classAttr = `(class|id)=(?:"(${p}${flag}${p})"|'(${p}${flag}${p})')[^>]*`
  let tagContent = `[\\w\\W]*?`;
  let scrValue = `(class|id|src)=(?:"(${p}(\w\W)*${p})")[^>]*`;
  let reg = new RegExp(`<([a-zA-Z]+)\\s+?${vueDataAttr}${scrValue}>`, "ig");
  return template.replace(reg, "");
}
