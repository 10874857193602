import request from "@/utils/request";

// import { getToken } from '@/utils/auth'

export function getInfoGet(params, url) {
  return request({
    url: url,
    method: "get",
    params
  });
}

export function getInfoPost(data, url) {
  return request({
    url: url,
    method: "post",
    data
  });
}
