<template>
  <div class="wrapper" v-loading="loading">
    <div class="dialogs"></div>
    <div class="f-wrapper">
      <!-- banner -->
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="item in imageList" :key="item.path">
          <img class="banner-imgs" :src="item.Img" />
        </el-carousel-item>
      </el-carousel>
      <!-- ads tips -->
      <div class="ads-tips vn-100">
        <p>践行亲情服务理念 提升供水服务水平</p>
        <p>—— 比用户的需求做的更好 ——</p>
        <div class="mobile-xsk vn-flex vn-space-between vn-100">
          <div
            v-for="item in barList"
            :key="item.ids"
            class="right-s-icon-item-box"
          >
            <div
              v-if="item.flag"
              class="right-s-icon-item"
              @click="handleTopList(item)"
            >
              <img :src="item.img" alt="" />
              <span>{{ item.text }}</span>
            </div>
            <a
              class="right-s-icon-item"
              v-else
              href="http://pps.cnwaterservice.com.cn/platform/m/article/detail?id=2670&digest=228683d82c2bf892d3fc4ebf1a36a8d80530347d"
              target="_blank"
            >
              <img :src="item.img" alt="" />
              <span>{{ item.text }}</span>
            </a>
          </div>
        </div>
      </div>
      <!--公司新闻-->
      <div class="vn-flex vn-space-between">
        <!-- 新闻栏 -->
        <div class="f-content vn-flex vn-flex-column">
          <div class="f-content-item-box vn-flex vn-space-between">
            <!--新闻轮播图-->
            <el-carousel
              :interval="5000"
              arrow="always"
              class="new-banners-none"
              style="width: 45%; height: 395px;"
            >
              <el-carousel-item
                v-for="item in newsImageList"
                :key="item.ID"
                style="width: 100%;height: 395px;"
              >
                <img
                  :src="item.Img"
                  style="width: 100%;height: 395px;"
                  @click="handleclick(item, '公司要闻')"
                />
              </el-carousel-item>
            </el-carousel>
            <div class="f-content-item">
              <!-- 公司要闻 -->
              <div
                class="f-content-item-tilte"
                @click="toNewsComIntro(5, '企业要闻', '/newslists')"
              >
                <span>公司要闻</span>
                <span>更多>>></span>
              </div>
              <div class="f-content-item-line vn-flex vn-flex-column">
                <replace-modules v-if="newsloading" />
                <div
                  v-for="item in newsListInfo"
                  :key="item.ID"
                  class="com-news1"
                  @click="handleclick(item, '公司要闻')"
                  style="font-size: 18px;line-height: 22px;margin-bottom: 15px;display:flex; flex-direction: column;"
                >
                  <span
                    style="font-size: 16px; font-weight: bold;margin: 0 0 5px 0;"
                    class="news-title"
                    >{{ item.Title }}</span
                  >
                  <!-- <span 
                                  v-html="item.Cont" 
                                  class="news-contents" 
                                  style="line-height: 22px;margin: -10px 0 0;font-size: 14px;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;">
                                  </span> -->
                </div>
              </div>
            </div>
          </div>
          <!-- 横条广告 -->
          <img
            class="f-img-box"
            :src="require('@/assets/guanggao.jpg')"
            alt=""
          />
          <!-- 公示广告 -->
          <div class="vn-flex vn-space-between" style="margin-top:20px;">
            <div
              @click="handleclick(comIntro, '公司介绍')"
              class="mobile-company f-call-box vn-50 vn-flex vn-flex-column"
              style="margin-right: 20px"
            >
              <div class="f-content-title-box f-call-left">
                <div class="f-content-title vn-flex vn-space-between">
                  <span>公司介绍</span>
                  <span></span>
                </div>
              </div>
              <div class="gsjs-box vn-flex">
                <!-- <img :src="require('@/assets/gsjs.jpg')" alt="" style="width: 200px; height: 137px;"> -->
                <div
                  v-html="comIntro.Cont"
                  style="height: 342px;font-size: 15px;margin-top: -17px;line-height: 25px;display: -webkit-box;overflow: hidden;-webkit-box-orient: vertical;color: #666;"
                ></div>
              </div>
            </div>
            <div class="f-call-box vn-50 vn-flex vn-flex-column">
              <div
                @click="toNewsComIntro(6, '用水常识', '/newslists')"
                class="f-content-title-box f-call-left"
              >
                <div class="f-content-title vn-flex vn-space-between">
                  <span>用水常识</span>
                  <span>更多>></span>
                </div>
              </div>
              <div class="f-call-left" style="margin-top: 15px;">
                <div
                  v-for="item in waterListIntro"
                  :key="item.ID"
                  @click="handleclick(item, '用水常识')"
                  class="f-content-item-line"
                >
                  <span style="font-size: 15px;">
                    {{ item.Title }}
                  </span>
                  <span
                    style="font-size: 13px;width: 150px;text-align: right;"
                    >{{ item.CreateDate }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
let p = "/";
import { getInfoGet, getInfoPost } from "@/api/public";
import ReplaceModules from "@/components/replaceModules";
import { delMarkTag } from "@/utils/validate";
export default {
  name: "Home",
  components: {
    ReplaceModules
  },
  data() {
    return {
      imageList: [],
      newsImageList: [],
      newsListInfo: [], // 新闻列表
      comIntro: {}, // 公司新闻
      comIntroProxy: {},
      // path: '/newsDetails',
      // query: {
      // titles: this.title,
      // ID: row.ID,
      // path: this.$route.fullPath
      // }?titles=&ID=
      barList: [
        {
          ids: 1,
          flag: true,
          id: 3,
          text: "停水公告",
          path: `/closeWater?path=${p}&titles=停水公告&ID=10000`,
          img: require("@/assets/sfchaxun.png")
        },
        {
          ids: 2,
          flag: true,
          id: 3,
          text: "缴费指南",
          path: `/newsDetails?path=${p}&titles=水费查询&ID=17`,
          img: require("@/assets/wuye.png")
        },
        {
          ids: 3,
          flag: true,
          text: "报装业务",
          path: "/baozhuang",
          img: require("@/assets/baozhuang.png")
        },
        {
          ids: 4,
          flag: true,
          id: 8,
          text: "水费价格",
          path: `/newsDetails?path=${p}&titles=水费价格&ID=16`,
          img: require("@/assets/sfjiage.png")
        },
        {
          ids: 5,
          flag: false,
          text: "联系我们",
          path: "",
          img: require("@/assets/us.png")
        }
      ],
      waterListIntro: [], // 用水常识
      loading: false,
      newsloading: false
    };
  },
  created() {
    if (this.waterListIntro.length) return;
    this.getIntroList(); // 获取公司介绍
    this.getImageList();
    this.getNewsList(); // 获取新闻列表
    this.getUserWaterList(); // 获取用水常识列表
  },
  methods: {
    async getImageList() {
      const r = await getInfoPost({}, "/api/DB/SelectImgPublic")
        .catch(e => {
          console.log("e", e);
        })
        .finally(() => {});
      if (!r || !r.Data || !r.Data.length) {
        this.imageList = [];
        return;
      }
      this.imageList = r.Data;
    },
    handleTopList(row) {
      this.$router.push({
        path: row.path,
        query: {
          titles: row.text,
          TypeID: row.id
        }
      });
    },
    toNewsComIntro(id, text, path) {
      this.$router.push({
        path: path,
        query: {
          titles: text,
          TypeID: id
        }
      });
    },
    handleclick(row, title) {
      console.log("row", row);
      this.$router.push({
        path: "/newsDetails",
        query: {
          titles: title,
          ID: row.ID,
          path: this.$route.fullPath
        }
      });
    },
    async getUserWaterList() {
      this.loading = true;
      const r = await getInfoPost(
        {
          TypeID: 6
        },
        "/api/DB/SelectPublic"
      )
        .catch(e => {
          console.log("e", e);
        })
        .finally(() => {
          this.loading = false;
        });
      if (!r || !r.Data || !r.Data.length) {
        this.waterListIntro = [];
        return;
      }
      this.waterListIntro = r.Data.splice(0, 10);
    },
    async getIntroList() {
      this.loading = true;
      const r = await getInfoPost(
        {
          TypeID: 1
        },
        "/api/DB/SelectPublic"
      )
        .catch(e => {
          console.log("e", e);
        })
        .finally(() => {
          this.loading = false;
        });
      if (!r || !r.Data || !r.Data.length) {
        this.comIntro = {};
        return;
      }
      // console.log('1', Object.assign({}, r.Data.splice(0, 1)[0]))
      this.comIntro = r.Data.splice(0, 1)[0];
      this.comIntro.Cont = delMarkTag(this.comIntro.Cont, null);
      // console.log('1000000', delMarkTag(this.comIntro.Cont, null))
      // this.comIntroProxy = Object.assign({}, r.Data.splice(0, 1)[0])
    },
    async getNewsList() {
      this.newsloading = true;
      const r = await getInfoPost(
        {
          TypeID: 5
        },
        "/api/DB/SelectPublic"
      )
        .catch(e => {
          console.log("e", e);
        })
        .finally(() => {
          this.newsloading = false;
        });
      if (!r || !r.Data || !r.Data.length) {
        this.newsListInfo = [];
        return;
      }
      r.Data.forEach(ele => {
        ele.Cont =
          ele.Cont.substring(0, 80).indexOf("img") > 0
            ? ""
            : ele.Cont.substring(0, 80) + "...";
      });
      this.newsImageList = r.Data.filter(ele => {
        return Number(ele["IfShow"]) === 1;
      });
      console.log("22222222", this.newsImageList);
      this.newsListInfo = r.Data.splice(0, 8);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../scss/public.scss";
@import "../scss/frame.scss";
@media screen and (min-width: 1000px) {
  .f-wrapper {
    width: 100%;
    margin-top: 5px;
  }
  .banner-img-box img {
    width: 100%;
  }
  /* 下边内容区 */
  .f-content-title-box {
    padding: 10px 20px;
    box-sizing: content-box;
    margin-top: 10px;
    border-bottom: 1px solid rgb(197, 197, 197);
    cursor: pointer;
  }
  .f-content-title span:nth-of-type(1) {
    font-size: 16px;
    color: #0660c7;
    font-weight: bold;
  }
  .f-content-title span:nth-of-type(2) {
    font-size: 14px;
    color: rgb(112, 112, 112);
    font-weight: bold;
  }
  .gsjs-box {
    margin-top: 20px;
    cursor: pointer;
  }
  .gsjs-box img {
    width: 200px;
    margin-right: 10px;
  }
  .f-content {
    width: 100%;
  }
  .f-content-item-box {
    padding: 15px;
    box-sizing: content-box;
    display: flex;
    justify-content: space-between;
  }
  .f-content-item {
    font-size: 16px;
    width: 48%;
    margin-left: 10px;
    padding: 0 25px;
  }
  /* 企业要闻 */
  .f-content-item-tilte {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  .f-content-item-tilte span:nth-of-type(1) {
    font-size: 25px;
    font-weight: bold;
    color: #0660c7;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-left: 15px;
  }
  .f-content-item-tilte span:nth-of-type(1):before {
    content: "";
    display: block;
    position: absolute;
    top: -6px;
    left: -20px;
    width: 10px;
    height: 50px;
    background-color: #0660c7;
  }
  .f-content-item-line {
    position: relative;
    padding: 10px 0;
    /*border-bottom: 1px dashed #ccc;*/
    display: flex;
    justify-content: space-between;
  }
  .f-content-item-line span {
    margin-left: 25px;
    color: #666;
  }
  .f-content-item-line a:hover {
    color: #0660c7;
  }
  .f-call-left .f-content-item-line a {
    margin: 15px 0 0 15px;
  }
  /* bannee下方 */
  .ads-tips {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .ads-tips p:nth-of-type(1) {
    color: #0f6db9;
    margin: 20px 15px;
    font-size: 20px;
    font-weight: bold;
  }
  .ads-tips p:nth-of-type(2) {
    color: rgb(168, 168, 168);
  }
  .f-wrapper .right-s-icon-item {
    width: 170px;
    padding: 10px 20px;
    margin: 30px 0 50px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .f-wrapper .right-s-icon-item img {
    width: 40px;
    height: 40px;
  }
  .f-wrapper .right-s-icon-item span {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-left: 15px;
  }
  .right-s-icon-item-box:nth-of-type(1) .right-s-icon-item {
    background: rgb(226, 116, 82);
  }
  .right-s-icon-item-box:nth-of-type(2) .right-s-icon-item {
    background: rgb(92, 168, 219);
  }
  .right-s-icon-item-box:nth-of-type(3) .right-s-icon-item {
    background: rgb(154, 108, 214);
  }
  .right-s-icon-item-box:nth-of-type(4) .right-s-icon-item {
    background: rgb(141, 209, 110);
  }
  .right-s-icon-item-box:nth-of-type(5) .right-s-icon-item {
    background: rgb(218, 157, 88);
  }
  .right-s-icon-item-box:nth-of-type(6) .right-s-icon-item {
    background: rgb(136, 127, 216);
  }
  .com-news1 {
    cursor: pointer;
  }
}

@media screen and (min-width: 320px) and (max-width: 970px) {
  .wrapper .f-wrapper {
    width: 100%;
  }
  .new-banners-none {
    display: none;
  }
  ::v-deep .el-carousel__container {
    height: 100px;
  }
  /* banner */
  .banner-imgs {
    width: 100%;
  }
  /* bannee下方 */
  .ads-tips {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .ads-tips p:nth-of-type(1) {
    color: #0f6db9;
    margin: 10px 6px;
    font-size: 18px;
    font-weight: bold;
  }
  .ads-tips p:nth-of-type(2) {
    color: rgb(168, 168, 168);
  }
  /* 5个小色块 */
  .mobile-xsk {
    margin: 15px 0;
    /* padding: 10px!important;
                box-sizing: content-box!important; */
    /* flex-wrap: wrap; */
  }
  .right-s-icon-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
  }
  .right-s-icon-item img {
    width: 25px;
  }
  .right-s-icon-item span {
    font-size: 14px;
    margin: 0px;
  }
  .right-s-icon-item-box:nth-of-type(1) .right-s-icon-item {
    background: rgb(226, 116, 82);
  }
  .right-s-icon-item-box:nth-of-type(2) .right-s-icon-item {
    background: rgb(92, 168, 219);
  }
  .right-s-icon-item-box:nth-of-type(3) .right-s-icon-item {
    background: rgb(154, 108, 214);
  }
  .right-s-icon-item-box:nth-of-type(4) .right-s-icon-item {
    background: rgb(141, 209, 110);
  }
  .right-s-icon-item-box:nth-of-type(5) .right-s-icon-item {
    background: rgb(218, 157, 88);
  }
  .right-s-icon-item-box:nth-of-type(6) .right-s-icon-item {
    background: rgb(136, 127, 216);
  }
  /* 隐藏新闻图片 */
  #test2 {
    display: none !important;
  }
  /* 新闻内容 */
  /* 企业要闻 */
  .f-content {
    width: 100%;
  }
  .f-content-item {
    box-sizing: content-box;
    width: 100%;
  }
  .f-content-item-tilte {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
  }
  .f-content-item-tilte span:nth-of-type(1) {
    font-size: 18px;
    font-weight: bold;
    color: #0660c7;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-left: 15px;
  }
  .f-content-item-tilte span:nth-of-type(2) {
    font-size: 16px;
    font-weight: normal;
    color: #0660c7;
    margin-right: 20px;
  }
  .f-content-item-tilte span:nth-of-type(1):before {
    content: "";
    display: block;
    position: absolute;
    top: -15px;
    left: -15px;
    width: 10px;
    height: 50px;
    background-color: #0660c7;
  }
  .f-content-item-line {
    position: relative;
    padding: 10px;
    border-bottom: 1px dashed #ccc;
    display: flex;
    justify-content: space-between;
  }
  .f-content-item-line .com-news {
    width: 100%;
    height: 200px;
  }
  .f-content-item-line .com-news span {
    font-size: 16px;
  }
  .news-contents {
    line-height: 18px !important;
  }
  .news-contents ::v-deep p {
    color: #666;
  }
  .f-content-item-line span:nth-of-type(1) {
    // margin-left: 25px;
    color: #666;
  }
  .f-content-item-line span:nth-of-type(2) {
    color: #0660c7;
    display: none;
  }
  // .f-call-left .f-content-item-line a {
  //     margin-left: 15px;
  // }
  /* 横条广告 */
  .f-img-box {
    width: 100%;
  }
  /* 公司介绍 */
  .mobile-company {
    display: none !important;
  }
  /* 用水常识 */
  .f-call-box {
    width: 100% !important;
    cursor: pointer;
  }
  .f-content-title {
    padding: 10px;
    box-sizing: content-box;
  }
  .f-content-title span:nth-of-type(1) {
    font-size: 16px;
    font-weight: bold;
    color: #0660c7;
  }
  .f-content-title span:nth-of-type(2) {
    font-size: 14px;
    color: #0660c7;
  }
}
</style>
