<template>
  <div class="bottom-bar-wrapper">
    <div class="bottom-bar-box">
      <span style="color: #fff;"
        >烟台市牟平区润新供水有限责任公司 版权所有</span
      >
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style="color: #fff; margin: 10px 0;">鲁ICP备2021045879号-1</span>
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home"
};
</script>

<style lang="scss" scoped>
@import "../scss/frame.scss";
</style>
