<template>
  <div
    v-if="visitedViews.fullPath !== '/'"
    class="com-box vn-flex vn-space-between"
    style="width: 100%;"
  >
    <div class="tags-box">
      当前页面为：
      <router-link to="/">首页</router-link>
      <span class="lines">/</span>
      <router-link :to="visitedViews.fullPath" style="font-weight: bold;">{{
        visitedViews.title ? visitedViews.title : addTitle
      }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView;
    },
    addTitle() {
      return this.$store.state.addTitles;
    }
  }
};
</script>

<style lang="scss" scoped>
.tags-box {
  margin: 30px 0 40px;
  display: flex;
  justify-content: flex-end;
}
.lines {
  padding: 0 5px;
}
@media screen and (min-width: 320px) and (max-width: 970px) {
  .com-box {
    display: flex;
    justify-content: center;
  }
}
</style>
