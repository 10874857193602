import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import getPageTitle from "@/utils/get-page-title";

import store from "../store";

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  // {
  //     path: '/company',
  //     component: () =>
  //         import ('@/views/company'),
  //     meta: { title: '公司介绍', icon: 'user' }
  // },
  {
    path: "/closeWater",
    component: () => import("@/views/closeWater"),
    meta: { title: "停水公告", icon: "user" }
  },
  {
    path: "/baozhuang",
    component: () => import("@/views/baozhuang"),
    meta: { title: "报装业务", icon: "user" }
  },
  // 新闻列表
  {
    path: "/newslists",
    component: () => import("@/views/newslists"),
    meta: { title: "", icon: "user" }
  },
  // 详情页
  {
    path: "/newsDetails",
    component: () => import("@/views/newsDetails"),
    meta: { title: "", icon: "user" }
  }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(ro, from, savePosition) {
    if (savePosition) {
      return savePosition;
    } else return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // SET_TAGSVIEWS
  store.commit("SET_TAGSVIEWS", {
    ...to.meta,
    fullPath: to.fullPath ? to.fullPath : ""
  });
  document.title = getPageTitle(to.meta.title);
  // store.commit("SET_CURRENTPAGEPATH", from)
  next();
});

export default router;
