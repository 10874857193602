import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
// import { getToken } from '@/utils/auth'
// const URL = 'http://122.5.30.252:6015'
//const URL = 'http://221.214.180.145:3005/'
//const URL = "http://120.53.222.45:3005/";
const URL = "https://www.mpgszgs.cn:3006/";

const service = axios.create({
  baseURL: URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // console.log('config', config)
    // let getTokenFiles = getToken()
    // if (getTokenFiles) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers["ClientKey"] = "0535deer";
    // }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data; // {Code: "200", Msg: "成功", Data: Array(2)}

    // if the custom code is not 20000, it is judged as an error.
    if (Array.isArray(res)) {
      return res;
    }
    if (Number(res.code) === 100) {
      return res;
    }
    if (Number(res.Code) !== 200) {
      Message({
        message: res.Msg ? res.Msg : "Error",
        type: "error",
        duration: 3 * 1000
      });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      return Promise.reject(
        new Error(res.Msg ? res.Msg : "服务器错误，请联系管理员！")
      );
    } else {
      return res;
    }
  },
  error => {
    console.log("响应报错：" + error); // for debug
    Message({
      message: error.Msg ? error.Msg : "服务器错误！",
      type: "error",
      duration: 3 * 1000
    });
    return Promise.reject(error);
  }
);

export default service;
