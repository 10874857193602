<template>
  <div class="modules-wra">
    <div class="modules-first-wra">
      <div class="modules-first"></div>
    </div>
    <div class="modules-list" v-for="item in modules" :key="item"></div>
  </div>
</template>

<script>
export default {
  props: {
    modules: {
      type: Number,
      default: 3
    }
  }
};
</script>

<style scoped>
.modules-wra {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modules-first {
  width: 90%;
  height: 20px;
  background-color: #e4e4e4;
}

.modules-list {
  width: 100%;
  height: 20px;
  background-color: #e4e4e4;
  margin-top: 10px;
}

.modules-first-wra {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
