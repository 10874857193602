import Vue from "vue";
import VueQuillEditor from "vue-quill-editor";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import router from "./router";
import store from "./store";
import App from "./App.vue";

Vue.use(VueQuillEditor);

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(ElementUI);
import {
  ElCarousel,
  ElCarouselItem,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
  ElSelect,
  ElOption,
  ElUpload,
  ElDialog
} from "element-ui";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
