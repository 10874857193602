<template>
  <div id="app">
    <pub-nav />
    <!--<transition name="fade-transform" mode="out-in"></transition>-->
    <div class="wrapper-box">
      <current-tags />
      <keep-alive>
        <router-view :key="fullPaths" />
      </keep-alive>
    </div>
    <bottom-footer />
  </div>
</template>

<script>
// @ is an alias to /src
import PubNav from "@/components/nav";
import BottomFooter from "@/components/bottom";
import CurrentTags from "@/components/currentTar";
export default {
  name: "Home",
  components: {
    PubNav,
    BottomFooter,
    CurrentTags
  },
  data() {
    return {
      fullPaths: ""
    };
  },
  watch: {
    $route(to, from) {
      this.fullPaths = to.fullPath;
    }
  }
};
</script>

<style lang="scss">
* {
  text-decoration: none;
}
a {
  color: #666;
}
#app {
  position: relative;
}
@media screen and (min-width: 1000px) {
  .wrapper-box {
    width: 1200px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 320px) and (max-width: 970px) {
  body {
    margin: 0px;
    padding: 0px;
  }
  .wrapper-box {
    width: 100%;
    padding-top: 70px;
  }
}
// .ql-editor {
//   white-space: normal !important;
// }

// .ql-container {
//   white-space: pre-wrap !important;
// }
</style>
