<template>
  <div class="hello top-nav-content">
    <img class="mobile-icon" :src="clickIcon" alt="" @click="handleMobile" />
    <div class="bg-img">
      <div class="com-name vn-flex vn-align-center">
        <img
          :src="require('@/assets/logo.jpg')"
          alt=""
          style="width: 80px;height: 80px;margin-right: 10px;"
        />
        <div>
          <span>烟台市牟平区润新供水有限责任公司</span>
          <span>Yantai Mouping Runxin Water Supply Co. LTD</span>
        </div>
      </div>
      <div class="yidong-nav vn-flex vn-align-center">
        <div class="vn-flex vn-align-center" style="margin-right: 10px;">
          <img
            :src="require('@/assets/ewm.jpg')"
            alt=""
            style="width: 100px; height: 100px;"
          />
          <span style="color: #5a5a5a;font-size: 12px;"
            >扫描二维码 <br />关注公众号
          </span>
        </div>
        <div class="vn-flex vn-align-center">
          <img class="phone-icon" :src="require('@/assets/ph.png')" alt="" />
          <span style="color: #535353;font-size: 12px;"
            >投诉电话:
            <br />
            <span style="font-size: 16px; font-weight: bold;"
              >4311111或4339332</span
            >
          </span>
        </div>
      </div>
    </div>
    <div class="b-content-box">
      <!-- 导航 -->
      <div class="b-nav" :class="{ showNav: isShowNav }">
        <div
          class="b-nav-list"
          :class="{ activeNav: item.isShowClass }"
          v-for="(item, index) in navList"
          :key="item.name"
          @click="handleMobileHidden"
        >
          <div
            class="nav-items"
            v-if="item.flag"
            @click="handleClick(item, index)"
          >
            <img :src="item.path" alt="#" /> {{ item.name }}
          </div>
          <a v-else class="nav-items" :href="item.path" target="_blank">
            <img :src="require('@/assets/shuizhi.png')" alt="3" />水质公告
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      navList: [
        {
          isShowClass: true,
          toLocal: "/",
          flag: true,
          name: "首页",
          path: require("@/assets/shouye.png")
        },
        {
          isShowClass: false,
          toLocal: "/company",
          flag: true,
          name: "公司概况",
          path: require("@/assets/gongsi.png")
        },
        {
          isShowClass: false,
          toLocal: "/closeWater",
          flag: true,
          name: "停水公告",
          path: require("@/assets/info.png")
        },
        {
          isShowClass: false,
          toLocal: "/waterNotice",
          flag: false,
          name: "水质公告",
          path:
            "http://pps.cnwaterservice.com.cn/platform/m/article/list?uid=389&msgtype=SZGG&nonce=638136c6-145a-4366-a3b7-4ce43090434c&signature=dde997ac62b13ff72fd6a60306bf248faddfe0bb#/"
        },
        {
          isShowClass: false,
          toLocal: "/service",
          flag: true,
          typeid: 2,
          name: "服务指南",
          path: require("@/assets/service.png")
        },
        {
          isShowClass: false,
          toLocal: "/jiaofei",
          flag: true,
          typeid: 3,
          name: "缴费指南",
          path: require("@/assets/online.png")
        },
        {
          isShowClass: false,
          toLocal: "/zhengce",
          flag: true,
          typeid: 4,
          name: "政策法规",
          path: require("@/assets/zhengce.png")
        },
        {
          isShowClass: false,
          toLocal: "/baozhuang",
          flag: true,
          name: "报装业务",
          path: require("@/assets/baozhuang-s.png")
        }
      ],
      isShowNav: false,
      clickIcon: require("@/assets/more.png")
      // clickIcon2: require('@/assets/close.png')"
    };
  },
  methods: {
    handleMobileHidden() {
      this.isShowNav = false;
    },
    handleMobile() {
      if (this.isShowNav) {
        this.isShowNav = false;
        this.clickIcon = require("@/assets/more.png");
      } else {
        this.isShowNav = true;
        this.clickIcon = require("@/assets/close.png");
      }
    },
    handleClick(row, index) {
      console.log("row", row);
      this.navList.forEach(ele => {
        ele.isShowClass = false;
      });
      this.clickIcon = require("@/assets/more.png");
      this.$set(this.navList[index], "isShowClass", true);
      if (row.toLocal === "/") {
        this.$router.push({
          path: "/"
        });
        return;
      }
      //  公司介绍
      if (row.toLocal === "/company") {
        this.$router.push({
          path: "/newsDetails",
          query: {
            titles: row.name,
            ID: 18,
            path: this.$route.fullPath
          }
        });
        return;
      }
      // 停水公告frame方式）
      if (row.toLocal === "/closeWater") {
        this.$router.push({
          path: row.toLocal,
          query: {
            titles: row.name,
            ID: 10000,
            path: this.$route.fullPath
          }
        });
        return;
      }
      // 报装
      if (row.toLocal === "/baozhuang") {
        this.$router.push({
          path: row.toLocal,
          query: {
            titles: row.name,
            path: this.$route.fullPath
          }
        });
        return;
      }
      //alert(row.typeid);
      this.$router.push({
        path: "/newslists",
        query: {
          titles: row.name,
          TypeID: row.typeid
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../scss/public.scss";
@import "../scss/frame.scss";
.activeNav {
  background: rgb(15, 109, 185);
}

:root {
  width: 100%;
  height: 100%;
  background-color: rgb(249, 253, 255);
}
</style>
